import React, { useState, useEffect } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Apiurl from "../../Apiurl";
import { Select } from "antd";
import { Input } from "antd";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import Button from "react-bootstrap/Button";
import { Tabhelper } from "../../Helper/Tabhelper";
import Subjectoptions from "../../Options/Subjectoptions";
import Courseoptions2 from "../../Options/Courseoptions2";

export default function Course_fee_update() {
  const [jsonList, setJsonList] = useState([]);
  //
  let dollarIndianLocale = Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 2,
  });
  const { user } = useParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const apiurl = Apiurl();
  const [formData, setFormData] = useState({});
  const [data2, setData2] = useState([]);
  const courseoptions = Courseoptions2();
  const subjectoptions = Subjectoptions();

  const [Subject_options, setsubject_options] = useState([]);
  const [Board_options, setboard_options] = useState([]);

  const [course, setcourse] = useState(null);
  const [batch, setbatch] = useState("");
  const [subject, setsubject] = useState("");
  const [board, setboard] = useState("");
  const [fee, setfee] = useState("");
  const [desc, setdesc] = useState("");
  async function postData(event) {
    event.preventDefault();
    const formdata = {
      course: course,
      batch: batch,
      board: board,
      subject: subject,
      fees: fee,
      description: desc,
    };
    try {
      console.log(formdata);
      const response = await axios.put(
        apiurl + `feestructure/${id}/`,
        formdata
      );
      console.log(response.responseText);
      fetchData();
      alert("Added Sucessfully");
      setcourse(null);
      setfee("");
      setsubject(null);
    } catch (error) {
      console.error(error);
      if (error.response.data.message) {
        alert(error.response.data.message);
      }
      //   console.error(error.response.data);
      // Handle error here
    }
  }

  async function fetchData() {
    try {
      const response = await axios.get(apiurl + `feestructure/${id}/`);
      console.log(response.data);
      setData2(response.data);
      setcourse(response.data.course);
      setbatch(response.data.batch);
      setsubject(response.data.subject);
      setboard(response.data.board);
      setfee(response.data.fees);
      setdesc(response.data.description);
    } catch (error) {
      console.error(error);
    }
  }
  async function fetchsubjectdata(val) {
    try {
      console.log("subject--" + val);
      if (val == "NEET" || val == "JEE") {
        setsubject_options([]);
      } else {
        const response = await axios.get(apiurl + `subject/?board=${val}`);
        const options = response.data.map((student, index) => ({
          value: student.subject,
          label: student.subject,
        }));
        setsubject_options(options);
        console.warn(options);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchboarddata(val) {
    try {
      if (val == "Y1N" || val == "Y2N") {
        const response = await axios.get(apiurl + `filterboard/?board=NEET`);
        const options = response.data.map((student, index) => ({
          value: student.board,
          label: student.board,
        }));
        setboard_options(options);
      } else if (val == "Y1J" || val == "Y2J") {
        const response = await axios.get(apiurl + `filterboard/?board=JEE`);
        const options = response.data.map((student, index) => ({
          value: student.board,
          label: student.board,
        }));
        setboard_options(options);
      } else if (val == "REP" || val == "YLP" || val == "CC") {
        const response = [{ board: "NEET" }, { board: "JEE" }];
        const options = response.map((student, index) => ({
          value: student.board,
          label: student.board,
        }));
        setboard_options(options);
      } else {
        const valueaf = removeAlphabets(val);
        console.log(valueaf);
        const response = await axios.get(
          apiurl + `filterboard/?board=${parseInt(valueaf)}`
        );
        const options = response.data.map((student, index) => ({
          value: student.board,
          label: student.board,
        }));
        setboard_options(options);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function removeAlphabets(str) {
    return str.replace(/[a-zA-Z]/g, "");
  }
  const handleChangecourse = (selectedOption) => {
    const val = selectedOption.split(",");
    setcourse(val[0]);

    fetchboarddata(val[0]);
    // fetchsubjectdata(val[0]);
    setdesc(val[1]);
  };
  const handleChangesubject = (selectedOption) => {
    setsubject(selectedOption);
  };
  const handleChangeboard = (selectedOption) => {
    setboard(selectedOption);
    fetchsubjectdata(selectedOption);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Maincomponent tabname={"Fee Structure"}>
      <div className="component-sub">
        <div className="component-sub1">
          <div className="multistephead">
            <b> FEE STRUCTURE UPDATE</b>
          </div>
          <br />
          <div className="Billcompmain">
            <div className="Billcompmain2">
              <div className="multistepcontainer1">
                <div className="multistepsubcontainer1">
                  <div className="multisteptable">
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Course</div>
                      <div className="multisteptablecell2">
                        <Select
                          style={{
                            width: "100%",
                          }}
                          className="textipbill"
                          value={course}
                          disabled
                          onChange={handleChangecourse}
                          options={courseoptions}
                          placeholder="Select Course"
                        />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Description</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          value={desc}
                          disabled
                          className="textipbill"
                        />
                      </div>
                    </div>

                    {/* <div className="multisteptablerow">
                      <div className="multisteptablecell1">Board</div>
                      <div className="multisteptablecell2">
                        <Select
                          style={{
                            width: "100%",
                          }}
                          className="textipbill"
                          disabled
                          value={board}
                          onChange={handleChangeboard}
                          options={Board_options}
                          placeholder="Select Subject"
                        />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Subject</div>
                      <div className="multisteptablecell2">
                        <Select
                          style={{
                            width: "100%",
                          }}
                          className="textipbill"
                          value={subject}
                          onChange={handleChangesubject}
                          options={Subject_options}
                          disabled
                          placeholder="Select Subject"
                        />
                      </div>
                    </div> */}
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Batch</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          value={batch}
                          disabled
                          onChange={(e) => {
                            setbatch(e.target.value);
                          }}
                          className="textipbill"
                        />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Fee</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          value={fee}
                          onChange={(e) => {
                            setfee(e.target.value);
                          }}
                          className="textipbill"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="multistepcontainerbtn">
                <button className="buttoncompnent1" onClick={postData}>
                  Submit
                </button>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="Billcompmain">{/* <Tablecomp data={data2} /> */}</div>
        </div>
      </div>
    </Maincomponent>
  );
}
