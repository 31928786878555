import React, { useState, useEffect } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { Select } from "antd";
import { Input } from "antd";
import { DatePicker, Space } from "antd";
import Logo from "../../Images/Sblogo1.png";
import Button from "react-bootstrap/Button";
import Courseoptions from "../../Options/Courseoptions";
import Branchoptions from "../../Options/Branchoptions";
import Apiurl from "../../Apiurl";
import axios from "axios";
import Referenceoptions from "../../Options/Referenceoptions";
import { Tabhelper } from "../../Helper/Tabhelper";
import Batchoptions from "../../Options/Batchoptions";
import Kitfeeamt from "../../Options/Kitfeeamt";

export default function Student_existing() {
  const courseoptions = Courseoptions();
  const branchoptions = Branchoptions();
  const referenceoptions = Referenceoptions();
  const batchoption = Batchoptions();
  const Kitfee = Kitfeeamt();
  const apiurl = Apiurl();
  let dollarIndianLocale = Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 2,
  });
  const [studentImage, setStudentImage] = useState(null);

  const [referencedata, setreferencedata] = useState([]);

  const [subjectoptions, setsubjectoptions] = useState([]);

  const [subjectvalue, setsubjectvalue] = useState([]);

  const [totalcount, settotalcount] = useState(0);

  const [show, setShow] = useState(true);
  const [existing, setexisting] = useState(false);

  const [isverified, setisverified] = useState(true);
  // const id = 9;
  const { id, user } = useParams();
  let navigate = useNavigate();
  const aduser = window.localStorage.getItem("userid");
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    gender: "",
    father_name: "",
    father_occupation: "",
    mother_name: "",
    mother_occupation: "",
    nationality: "",
    category: "",
    district: "",
    contact1: "",
    contact2: "",
    date_of_birth: "",
    school_name: "",
    address: "",
    pincode: "",
    state: "",
    board: "",
    group: "",
    mark_10: "",
    mark_11: "",
    mark_12: "",
    course: "",
    batch: "",
    duration: "",
    total_fee: 0,
    join_date: "",
    end_date: null,
    branch: "",
    scholorship: "No",
    scholorship_percentage: 0,
    source: "",
    about_studibreeze: "",
    student_id: "",
    school_state: "",
    school_district: "",
    status: "Active",
    standard: "",
    sno: "",
    religion: "",
    school_address: "",
    school_pincode: "",
    reference: "",
    after_scholorship: 0,
    scholorship_amount: 0,
    fee_paid: 0,
    remainig_fee: 0,
    subject: [],
    std_name: "",
    aduser: aduser,
    appearedbefore: "",
    abroad: "",
    appearedscore: "",
    subject: "",
    course_batch: "",
    kitfee: "No",
    kitfeeamount: 0,
  });
  const [username, setUsername] = useState("");
  // const personalitem = [
  //   {
  //     name: "firstname",
  //     placeholder: "First Name",
  //     type: "inputtext",
  //     isrequired: true,
  //     Label: "First Name",
  //     options: [],
  //     isdisabled: false,
  //   },
  //   {
  //     name: "lastname",
  //     type: "inputtext",
  //     Label: "Last Name",
  //     isrequired: true,
  //     placeholder: "Last Name",
  //     options: [],
  //     isdisabled: false,
  //   },

  //   {
  //     name: "father_name",
  //     type: "inputtext",
  //     Label: "Father Name",
  //     placeholder: "Father Name",
  //     options: [],
  //     isdisabled: false,
  //     isrequired: false,
  //   },
  //   {
  //     name: "father_occupation",
  //     placeholder: "",
  //     type: "inputtext",
  //     Label: " Occupation",
  //     placeholder: "Father Occupation",
  //     options: [],
  //     isdisabled: false,
  //     isrequired: false,
  //   },
  //   {
  //     name: "mother_name",
  //     placeholder: "Mother Name",
  //     type: "inputtext",
  //     Label: "Mother Name",
  //     options: [],
  //     isdisabled: false,
  //     isrequired: false,
  //   },
  //   {
  //     name: "mother_occupation",
  //     placeholder: "Mother Occupation",
  //     type: "inputtext",
  //     Label: " Occupation",
  //     options: [],
  //     isdisabled: false,
  //     isrequired: false,
  //   },

  //   {
  //     name: "date_of_birth",
  //     placeholder: "Date Of Birth",
  //     type: "inputdate",
  //     Label: "Date Of Birth",
  //     options: [],
  //     isdisabled: false,
  //     isrequired: true,
  //   },
  //   {
  //     name: "gender",
  //     placeholder: "Gender",
  //     type: "inputdrop",
  //     Label: "Gender",
  //     options: [
  //       {
  //         value: "Male",
  //         label: "Male",
  //       },
  //       {
  //         value: "Female",
  //         label: "Female",
  //       },
  //       {
  //         value: "Transgender",
  //         label: "Transgender",
  //       },
  //     ],
  //     isdisabled: false,
  //     isrequired: true,
  //   },
  //   {
  //     name: "contact1",
  //     type: "inputtext",
  //     placeholder: "Contact Number",
  //     Label: "Contact Number",
  //     options: [],
  //     isdisabled: false,
  //     isrequired: true,
  //   },
  //   {
  //     name: "contact2",
  //     type: "inputtext",
  //     Label: "Parent Number",
  //     options: [],
  //     placeholder: " Parent Number",
  //     isdisabled: false,
  //     isrequired: true,
  //   },
  //   {
  //     name: "email",
  //     type: "inputtext",
  //     Label: "Email",
  //     placeholder: "Email",
  //     options: [],
  //     isdisabled: false,
  //     isrequired: false,
  //   },
  //   {
  //     name: "category",
  //     type: "inputdrop",
  //     Label: "Category",
  //     placeholder: "Category",
  //     options: [
  //       {
  //         value: "General",
  //         label: "General",
  //       },
  //       {
  //         value: "OBC",
  //         label: "OBC",
  //       },
  //       {
  //         value: "SC",
  //         label: "SC",
  //       },
  //       {
  //         value: "ST",
  //         label: "ST",
  //       },
  //       {
  //         value: "BC",
  //         label: "BC",
  //       },
  //       {
  //         value: "MBC",
  //         label: "MBC",
  //       },
  //     ],
  //     isdisabled: false,
  //     isrequired: false,
  //   },
  //   {
  //     name: "address",
  //     type: "inputtext",
  //     placeholder: "Address",
  //     Label: "Address",
  //     options: [],
  //     isdisabled: false,
  //     isrequired: false,
  //   },
  //   {
  //     name: "pincode",
  //     type: "inputtext",
  //     placeholder: "Pincode",
  //     Label: "Pincode",
  //     options: [],
  //     isdisabled: false,
  //     isrequired: false,
  //   },

  //   {
  //     name: "district",
  //     type: "inputtext",
  //     Label: "District",
  //     placeholder: "District",
  //     options: [],
  //     isdisabled: false,
  //     isrequired: false,
  //   },

  //   {
  //     name: "state",
  //     type: "inputtext",
  //     Label: "State",
  //     placeholder: "State",
  //     options: [],
  //     isdisabled: false,
  //     isrequired: false,
  //   },
  //   {
  //     name: "religion",
  //     type: "inputdrop",
  //     Label: "Religion",
  //     placeholder: "Religion",
  //     options: [
  //       {
  //         name: "Christian",
  //         value: "Christian",
  //       },
  //       {
  //         name: "Hindu",
  //         value: "Hindu",
  //       },
  //       {
  //         name: "Muslim",
  //         value: "Muslim",
  //       },
  //       {
  //         name: "Other",
  //         value: "Other",
  //       },
  //     ],
  //     isdisabled: false,
  //     isrequired: true,
  //   },

  //   {
  //     name: "nationality",
  //     type: "inputtext",
  //     placeholder: "Nationality",
  //     Label: "Nationality",
  //     options: [],
  //     isdisabled: false,
  //     isrequired: false,
  //   },
  // ];
  const personalitem = [
    {
      name: "firstname",
      placeholder: "First Name",
      type: "inputtext",
      isrequired: true,
      Label: "First Name",
      options: [],
      isdisabled: false,
    },
    {
      name: "lastname",
      type: "inputtext",
      Label: "Last Name",
      isrequired: true,
      placeholder: "Last Name",
      options: [],
      isdisabled: false,
    },

    {
      name: "father_name",
      type: "inputtext",
      Label: "Father Name",
      placeholder: "Father Name",
      options: [],
      isdisabled: false,
      isrequired: false,
    },
    // {
    //   name: "father_occupation",
    //   placeholder: "",
    //   type: "inputtext",
    //   Label: " Occupation",
    //   placeholder: "Father Occupation",
    //   options: [],
    //   isdisabled: false,
    //   isrequired: false,
    // },
    {
      name: "mother_name",
      placeholder: "Mother Name",
      type: "inputtext",
      Label: "Mother Name",
      options: [],
      isdisabled: false,
      isrequired: false,
    },
    // {
    //   name: "mother_occupation",
    //   placeholder: "Mother Occupation",
    //   type: "inputtext",
    //   Label: " Occupation",
    //   options: [],
    //   isdisabled: false,
    //   isrequired: false,
    // },

    {
      name: "date_of_birth",
      placeholder: "Date Of Birth",
      type: "inputdate",
      Label: "Date Of Birth",
      options: [],
      isdisabled: false,
      isrequired: true,
    },
    {
      name: "gender",
      placeholder: "Gender",
      type: "inputdrop",
      Label: "Gender",
      options: [
        {
          value: "Male",
          label: "Male",
        },
        {
          value: "Female",
          label: "Female",
        },
        {
          value: "Transgender",
          label: "Transgender",
        },
      ],
      isdisabled: false,
      isrequired: true,
    },
    {
      name: "contact1",
      type: "inputtext",
      placeholder: "Contact Number",
      Label: "Contact Number",
      options: [],
      isdisabled: false,
      isrequired: true,
    },
    {
      name: "contact2",
      type: "inputtext",
      Label: "Parent Number",
      options: [],
      placeholder: " Parent Number",
      isdisabled: false,
      isrequired: true,
    },
    {
      name: "email",
      type: "inputtext",
      Label: "Email",
      placeholder: "Email",
      options: [],
      isdisabled: false,
      isrequired: false,
    },
    // {
    //   name: "category",
    //   type: "inputdrop",
    //   Label: "Category",
    //   placeholder: "Category",
    //   options: [
    //     {
    //       value: "General",
    //       label: "General",
    //     },
    //     {
    //       value: "OBC",
    //       label: "OBC",
    //     },
    //     {
    //       value: "SC",
    //       label: "SC",
    //     },
    //     {
    //       value: "ST",
    //       label: "ST",
    //     },
    //     {
    //       value: "BC",
    //       label: "BC",
    //     },
    //     {
    //       value: "MBC",
    //       label: "MBC",
    //     },
    //   ],
    //   isdisabled: false,
    //   isrequired: false,
    // },
    {
      name: "address",
      type: "inputtext",
      placeholder: "Address",
      Label: "Address",
      options: [],
      isdisabled: false,
      isrequired: false,
    },

    // {
    //   name: "district",
    //   type: "inputtext",
    //   Label: "District",
    //   placeholder: "District",
    //   options: [],
    //   isdisabled: false,
    //   isrequired: false,
    // },

    // {
    //   name: "state",
    //   type: "inputtext",
    //   Label: "State",
    //   placeholder: "State",
    //   options: [],
    //   isdisabled: false,
    //   isrequired: false,
    // },
    // {
    //   name: "pincode",
    //   type: "inputtext",
    //   placeholder: "Pincode",
    //   Label: "Pincode",
    //   options: [],
    //   isdisabled: false,
    //   isrequired: false,
    // },
    // {
    //   name: "religion",
    //   type: "inputdrop",
    //   Label: "Religion",
    //   placeholder: "Religion",
    //   options: [
    //     {
    //       name: "Christian",
    //       value: "Christian",
    //     },
    //     {
    //       name: "Hindu",
    //       value: "Hindu",
    //     },
    //     {
    //       name: "Muslim",
    //       value: "Muslim",
    //     },
    //     {
    //       name: "Other",
    //       value: "Other",
    //     },
    //   ],
    //   isdisabled: false,
    //   isrequired: true,
    // },
    {
      name: "standard",
      type: "inputdrop",
      placeholder: "Standard",
      Label: "Std",
      options: [
        {
          value: "Class 4",
          label: "Class 4",
        },
        {
          value: "Class 5",
          label: "Class 5",
        },
        {
          value: "Class 6",
          label: "Class 6",
        },
        {
          value: "Class 7",
          label: "Class 7",
        },
        {
          value: "Class 8",
          label: "Class 8",
        },
        {
          value: "Class 9",
          label: "Class 9",
        },
        {
          value: "Class 10",
          label: "Class 10",
        },
        {
          value: "Class 11",
          label: "Class 11",
        },
        {
          value: "Class 12",
          label: "Class 12",
        },
      ],
      isdisabled: false,
      isrequired: false,
    },
    {
      name: "board",
      type: "inputdrop",
      placeholder: "Board",
      Label: "Board",
      options: [
        {
          value: "SB",
          label: "SB",
        },
        {
          value: "CBSE",
          label: "CBSE",
        },
        {
          value: "ICSE",
          label: "ICSE",
        },
      ],
      isdisabled: false,
      isrequired: false,
    },
    {
      name: "group",
      type: "inputdrop",
      placeholder: "Group",
      Label: "Group",
      options: [
        {
          value: "PCB",
          label: "PCB",
        },
        {
          value: "PCM",
          label: "PCM",
        },
        {
          value: "PCMB",
          label: "PCMB",
        },
        {
          value: "MS",
          label: "MS",
        },
      ],
      isdisabled: false,
      isrequired: false,
    },
    {
      name: "school_name",
      type: "inputtext",
      Label: "School Name",
      placeholder: "School Name",
      options: [],
      isdisabled: false,
      isrequired: false,
    },

    // {
    //   name: "nationality",
    //   type: "inputtext",
    //   placeholder: "Nationality",
    //   Label: "Nationality",
    //   options: [],
    //   isdisabled: false,
    //   isrequired: false,
    // },
  ];
  const phasetwo = [
    {
      name: "standard",
      type: "inputdrop",
      placeholder: "Standard",
      Label: "Std",
      options: [
        {
          value: "Class 4",
          label: "Class 4",
        },
        {
          value: "Class 5",
          label: "Class 5",
        },
        {
          value: "Class 6",
          label: "Class 6",
        },
        {
          value: "Class 7",
          label: "Class 7",
        },
        {
          value: "Class 8",
          label: "Class 8",
        },
        {
          value: "Class 9",
          label: "Class 9",
        },
        {
          value: "Class 10",
          label: "Class 10",
        },
        {
          value: "Class 11",
          label: "Class 11",
        },
        {
          value: "Class 12",
          label: "Class 12",
        },
      ],
      isdisabled: false,
      isrequired: false,
    },
    {
      name: "board",
      type: "inputdrop",
      placeholder: "Board",
      Label: "Board",
      options: [
        {
          value: "SB",
          label: "SB",
        },
        {
          value: "CBSE",
          label: "CBSE",
        },
        {
          value: "ICSE",
          label: "ICSE",
        },
      ],
      isdisabled: false,
      isrequired: false,
    },
    {
      name: "group",
      type: "inputdrop",
      placeholder: "Group",
      Label: "Group",
      options: [
        {
          value: "PCB",
          label: "PCB",
        },
        {
          value: "PCM",
          label: "PCM",
        },
        {
          value: "PCMB",
          label: "PCMB",
        },
        {
          value: "MS",
          label: "MS",
        },
      ],
      isdisabled: false,
      isrequired: false,
    },
    {
      name: "school_name",
      type: "inputtext",
      Label: "School Name",
      placeholder: "School Name",
      options: [],
      isdisabled: false,
      isrequired: false,
    },
    {
      name: "school_address",
      type: "inputtext",
      Label: "Address",
      placeholder: "Address",
      options: [],
      isdisabled: false,
      isrequired: false,
    },
    {
      name: "school_pincode",
      type: "inputtext",
      placeholder: "Pincode",
      Label: "Pincode",
      options: [],
      isdisabled: false,
      isrequired: false,
    },
    {
      name: "school_state",
      type: "inputtext",
      Label: "State",
      placeholder: "State",
      options: [],
      isdisabled: false,
      isrequired: false,
    },
    {
      name: "school_district",
      type: "inputtext",
      Label: "District",
      placeholder: "District",
      options: [],
      isdisabled: false,
      isrequired: false,
    },

    {
      name: "mark_10",
      type: "inputtext",
      placeholder: "10th Mark",
      Label: "10th Mark",
      options: [],
      isdisabled: false,
      isrequired: false,
    },
    {
      name: "mark_11",
      type: "inputtext",
      placeholder: "11th Mark",
      Label: "11th Mark",
      options: [],
      isdisabled: false,
      isrequired: false,
    },
    {
      name: "mark_12",
      type: "inputtext",
      placeholder: "12th Mark",
      Label: "12th Mark",
      options: [],
      isdisabled: false,
      isrequired: false,
    },
    {
      name: "appearedbefore",
      type: "inputdrop",
      placeholder: "Appeared NEET/JEE",
      Label: "Appeared NEET/JEE",
      options: [
        {
          name: "Yes",
          value: "Yes",
        },
        {
          name: "No",
          value: "No",
        },
      ],
      isdisabled: false,
      isrequired: false,
    },
    // {
    //   name: "appearedate",
    //   type: "inputtext",
    //   Label: "If YES date of Appeared",
    //   options: [],
    //   isdisabled: false,
    // },
    {
      name: "abroad",
      type: "inputtext",
      placeholder: "Abroad Study(Country)",
      Label: "Abroad Study(Country)",
      options: [],
      isdisabled: false,
      isrequired: false,
    },
    {
      name: "appearedscore",
      type: "inputtext",
      Label: "NEET/ JEE Score",
      placeholder: "NEET/ JEE Score",
      options: [],
      isdisabled: false,
      isrequired: false,
    },
  ];
  const phasethree = [
    {
      name: "course",
      type: "inputdrop",
      Label: "Course",
      placeholder: "Course",
      options: courseoptions,
      isdisabled: false,
      isrequired: true,
      isvisible: true,
      isamount: false,
    },
    {
      name: "subject",
      type: "multiselectdrop",
      Label: "Subject",
      placeholder: "Subject",
      options: subjectoptions,
      isdisabled: false,
      isrequired: true,
      isvisible: false,
      isamount: false,
    },
    {
      name: "course_batch",
      type: "inputdrop",
      Label: "Batch",
      options: batchoption,
      placeholder: "Batch",
      isdisabled: false,
      isrequired: true,
      isvisible: true,
      isamount: false,
    },
    {
      name: "duration",
      type: "inputtext",
      Label: "Duration",
      options: [],
      placeholder: "Duration",
      isdisabled: false,
      isrequired: false,
      isvisible: true,
      isamount: false,
    },
    {
      name: "total_fee",
      type: "inputtext",
      Label: "Total Fee",
      placeholder: "Total Fee",
      options: [],
      isdisabled: true,
      isrequired: true,
      isvisible: true,
      isamount: true,
    },
    {
      name: "scholorship",
      type: "inputdrop",
      placeholder: "Scholarship",
      Label: "Scholarship",
      options: [
        {
          name: "Yes",
          value: "Yes",
        },
        {
          name: "No",
          value: "No",
        },
      ],
      isdisabled: false,
      isrequired: true,
      isvisible: true,
      isamount: false,
    },

    {
      name: "scholorship_percentage",
      type: "inputtext",
      Label: "Scholarship",
      placeholder: "Scholarship Amount",
      options: [],
      isdisabled: formData.scholorship !== "Yes",
      isrequired: formData.scholorship === "Yes",
      isvisible: true,
      isamount: false,
    },
    {
      name: "kitfee",
      type: "inputdrop",
      placeholder: "Kit Fee",
      Label: "Kit Fee",
      options: [
        {
          name: "Yes",
          value: "Yes",
        },
        {
          name: "No",
          value: "No",
        },
      ],
      isdisabled: false,
      isrequired: true,
      isvisible: true,
      isamount: false,
    },
    {
      name: "branch",
      type: "inputdrop",
      Label: "Branch",
      options: branchoptions,
      placeholder: "Branch",
      isdisabled: false,
      isrequired: true,
      isvisible: true,
      isamount: false,
    },
    {
      name: "join_date",
      type: "inputdate",
      Label: "Join Date",
      placeholder: "Join Date",
      options: [],
      isdisabled: false,
      isrequired: true,
      isvisible: true,
      isamount: false,
    },

    {
      name: "end_date",
      type: "inputdate",
      Label: "Finish Date",
      placeholder: "Finish Date",
      options: [],
      isdisabled: false,
      isrequired: false,
      isvisible: true,
      isamount: false,
    },
    {
      name: "reference",
      type: "inputdrop",
      placeholder: "Reference",
      Label: "Reference",
      options: referenceoptions,
      isdisabled: false,
      isrequired: false,
      isvisible: true,
      isamount: false,
    },
    {
      name: "source",
      placeholder: "Source",
      type: "inputdrop",
      Label: "Source",
      options: [
        {
          name: "Walk In",
          value: "Walk In",
        },
        {
          name: "Justdail",
          value: "Justdail",
        },
        {
          name: "Website",
          value: "Website",
        },
        {
          name: "Data",
          value: "Data",
        },
        {
          name: "Enquiry",
          value: "Enquiry",
        },
        {
          name: "Others",
          value: "Others",
        },
      ],
      isdisabled: false,
      isrequired: false,
      isvisible: true,
      isamount: false,
    },
    {
      name: "status",
      type: "inputdrop",
      placeholder: "Status",
      Label: "Status",
      options: [
        {
          name: "Active",
          value: "Active",
        },
        {
          name: "Drop",
          value: "Drop",
        },
        {
          name: "Completed",
          value: "Completed",
        },
      ],
      isdisabled: false,
      isrequired: false,
      isvisible: true,
      isamount: false,
    },
    // {
    //   name: "about_studibreeze",
    //   type: "inputtext",
    //   Label: "How Do you Came to know about Studi Breeze?",
    //   options: [],
    //   isdisabled: false,
    // },
    {
      name: "student_id",
      type: "inputtext",
      placeholder: "Student ID",
      Label: "Student ID",
      options: [],
      isdisabled: true,
      isrequired: true,
      isvisible: true,
      isamount: false,
    },
    {
      name: "sno",
      type: "inputtext",
      placeholder: "S.No",
      Label: "S.No",
      options: [],
      isdisabled: true,
      isrequired: true,
      isvisible: true,
      isamount: false,
    },
  ];
  // const tabData = {
  //   name: "Tab 2",
  //   link: "/student_registration",
  //   active: "no",
  // };
  // console.warn(personalitem.length());
  // const halfLength = Math.ceil(personalitem.length / 2);
  // const firstColumn = personalitem.slice(0, halfLength);
  // const secondColumn = personalitem.slice(halfLength);
  const [currentStep, setCurrentStep] = useState(1);

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };
  const calculateSum = (arr) => {
    let sum = 0;
    arr.forEach((item) => {
      // Extracting the value part before the comma
      const value = parseInt(item.split(",")[0]);
      // Checking if the value contains "2000"
      if (!isNaN(value)) {
        sum += value;
      }
    });
    return sum;
  };

  async function fetchfeedata(val) {
    try {
      const response = await axios.get(
        apiurl + `feestructure/?course=${val}&batch=${formData.batch}&subject=`
      );
      if (Array.isArray(response.data) && response.data.length > 0) {
        const totalFee = parseInt(response.data[0].fees);
        // if (val == "REP" || val == "YLP") {
        //   setFormData((prevState) => ({
        //     ...prevState,
        //     total_fee: totalFee,
        //   }));
        // } else {
        //   setFormData((prevState) => ({
        //     ...prevState,
        //     total_fee: 0,
        //   }));
        // }
        setFormData((prevState) => ({
          ...prevState,
          total_fee: totalFee,
        }));

        console.log(totalFee);

        const convertedArray = response.data.map((item) => ({
          value: `${item.fees},${item.subject},${item.board}`,
          label: `${item.subject}-${item.board} `,
        }));

        setsubjectoptions(convertedArray);

        console.warn(convertedArray);
      } else {
        alert(
          "Couldn't able to fetch the Fee Structure for the  Course !\nPlease Check the Data."
        );
        console.error("Response data is empty or not in expected format");
        setFormData((prevState) => ({
          ...prevState,
          total_fee: 0,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name == "course") {
      console.log("course");
      fetchfeedata(value);
      setFormData((prevState) => ({
        ...prevState,
        subject: [],
      }));
      setsubjectoptions([]);
    }
    if (name == "subject") {
      setsubjectvalue(value);
      const s = calculateSum(value);
      setFormData((prevState) => ({
        ...prevState,
        total_fee: s,
      }));
      console.log(s);
    }
    if (name == "kitfee") {
      console.log("kitfee");
      setFormData((prevState) => ({
        ...prevState,
        kitfee: formData.kitfee === "No" ? "Yes" : "No",
        kitfeeamount: formData.kitfeeamount === 0 ? Kitfee : 0,
      }));
      setisverified(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const formdata = new FormData();
      setFormData((prevState) => ({
        ...prevState,
        studend_image: null,
      }));

      const formdata2 = {
        username: formData.student_id,
        email: "",
        password: "ashtrix",
      };
      console.log(formData);
      const response = await axios.post(apiurl + `studentreg/`, formData);
      const responseb = await axios.post(apiurl + "register/", formdata2);
      const formData4 = {
        status: "Completed",
      };
      console.log(formData);
      const responsec = await axios.patch(
        apiurl + `studentreg/${id}/`,
        formData4
      );
      const formdata3 = {
        username: username,
      };
      // const response = await axios.post(apiurl + `delete-account/`, formdata3);
      // console.log(response);

      alert("Registered");
      navigate(`/dashboard/${user}`);
    } catch (error) {
      alert("ERROR");
      console.log(error);
    }
  };

  const Formfooter = () => {
    return (
      <>
        <div className="multiformfooter">
          <Button
            onClick={() => {
              setCurrentStep(1);
              setisverified(false);
            }}
            size="sm"
            variant={currentStep == 1 ? "primary" : "outline-primary"}
          >
            General
          </Button>
          &nbsp;&nbsp;&nbsp;
          {/* <Button
            onClick={() => {
              setCurrentStep(2);
              setisverified(false);
            }}
            size="sm"
            variant={currentStep == 2 ? "primary" : "outline-primary"}
          >
            Educational
          </Button> */}
          &nbsp;&nbsp;&nbsp;
          <Button
            onClick={() => {
              setCurrentStep(3);
              setisverified(false);
            }}
            size="sm"
            variant={currentStep == 3 ? "primary" : "outline-primary"}
          >
            Fee
          </Button>
          {currentStep == 3 && (
            <>
              {" "}
              &nbsp;&nbsp;&nbsp;{" "}
              <Button
                onClick={generateID}
                size="sm"
                variant={isverified ? "success" : "outline-success"}
              >
                Verify
              </Button>
              &nbsp;&nbsp;&nbsp;{" "}
              {isverified && (
                <Button
                  onClick={handleSubmit}
                  size="sm"
                  variant="outline-success"
                >
                  Confirm
                </Button>
              )}
            </>
          )}
        </div>
      </>
    );
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="multistepmaincontainer">
            <div className="multistephead">
              {" "}
              <b>PERSONAL DETAILS</b>
            </div>
            <div className="multistepcontainer1">
              <div className="multistepsubcontainer1">
                <div className="multisteptable">
                  {/* ... (your existing code) */}
                  {personalitem.map((item, index) => (
                    <div className="multisteptablerow" key={index}>
                      <div className="multisteptablecell1">
                        {item.Label}
                        <span style={{ color: "red" }}>
                          {" "}
                          {item.isrequired ? "*" : null}{" "}
                        </span>
                      </div>
                      <div className="multisteptablecell2">
                        {item.type === "inputtext" ? (
                          <Input
                            type="text"
                            name={item.name}
                            placeholder={item.placeholder}
                            value={formData[item.name]}
                            onChange={(e) =>
                              handleChange(item.name, e.target.value)
                            }
                          />
                        ) : item.type === "inputdate" ? (
                          <>
                            {" "}
                            <Input
                              type="date"
                              name={item.name}
                              placeholder={item.placeholder}
                              value={formData[item.name]}
                              onChange={(e) =>
                                handleChange(item.name, e.target.value)
                              }
                            />
                          </>
                        ) : (
                          <>
                            <Select
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              placeholder={item.placeholder}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "").includes(input)
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              value={formData[item.name] || undefined}
                              onChange={(value) =>
                                handleChange(item.name, value)
                              }
                              options={item.options}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* <div className="multiformfooter">
                <button className="multiformbtn" onClick={nextStep}>
                  Next
                </button>
              </div> */}
            <Formfooter />
          </div>
        );
      case 2:
        return (
          <div className="multistepmaincontainer">
            <div className="multistephead">
              {" "}
              <b>EDUCATIONAL DETAILS</b>
            </div>
            <div className="multistepcontainer1">
              <div className="multistepsubcontainer1">
                <div className="multisteptable">
                  {phasetwo.map((item, index) => (
                    <div className="multisteptablerow" key={index}>
                      <div className="multisteptablecell1">
                        {item.Label}
                        <span style={{ color: "red" }}>
                          {" "}
                          {item.isrequired ? "*" : null}{" "}
                        </span>
                      </div>
                      <div className="multisteptablecell2">
                        {item.type === "inputtext" ? (
                          <Input
                            type="text"
                            name={item.name}
                            placeholder={item.placeholder}
                            value={formData[item.name]}
                            onChange={(e) =>
                              handleChange(item.name, e.target.value)
                            }
                          />
                        ) : item.type === "inputdate" ? (
                          <>
                            {" "}
                            <Input
                              type="date"
                              name={item.name}
                              placeholder={item.placeholder}
                              value={formData[item.name]}
                              onChange={(e) =>
                                handleChange(item.name, e.target.value)
                              }
                            />
                          </>
                        ) : (
                          <>
                            <Select
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              placeholder={item.placeholder}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "").includes(input)
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              value={formData[item.name] || undefined}
                              onChange={(value) =>
                                handleChange(item.name, value)
                              }
                              options={item.options}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* <div className="multiformfooter">
                <button className="multiformbtn" onClick={prevStep}>
                  Previous
                </button>
                &nbsp;&nbsp;&nbsp;
                <button className="multiformbtn" onClick={nextStep}>
                  Next
                </button>
              </div> */}
            <Formfooter />
          </div>
        );
      case 3:
        return (
          <div className="multistepmaincontainer">
            <div className="multistephead">
              {" "}
              <b>OFFICE USE</b>
            </div>
            <div className="multistepcontainer1">
              <div className="multistepsubcontainer1">
                <div className="multisteptable">
                  {phasethree.map((item, index) => (
                    <>
                      {item.isvisible ? (
                        <div className="multisteptablerow" key={index}>
                          <div className="multisteptablecell1">
                            {item.Label}
                            <span style={{ color: "red" }}>
                              {" "}
                              {item.isrequired ? "*" : null}{" "}
                            </span>
                          </div>
                          <div className="multisteptablecell2">
                            {item.type === "inputtext" ? (
                              <Input
                                type="text"
                                name={item.name}
                                placeholder={item.placeholder}
                                value={
                                  item.isamount
                                    ? dollarIndianLocale.format(
                                        formData[item.name]
                                      )
                                    : formData[item.name]
                                }
                                disabled={item.isdisabled}
                                onChange={(e) =>
                                  handleChange(item.name, e.target.value)
                                }
                              />
                            ) : item.type === "inputdate" ? (
                              <Input
                                type="date"
                                name={item.name}
                                placeholder={item.placeholder}
                                value={formData[item.name]}
                                onChange={(e) =>
                                  handleChange(item.name, e.target.value)
                                }
                              />
                            ) : item.type === "multiselectdrop" ? (
                              <Select
                                mode="multiple"
                                showSearch
                                style={{
                                  width: "100%",
                                }}
                                placeholder={item.placeholder}
                                optionFilterProp="children"
                                disabled={item.isdisabled}
                                filterOption={(input, option) =>
                                  (option?.label ?? "").includes(input)
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase()
                                    )
                                }
                                value={formData[item.name] || []}
                                onChange={(value) =>
                                  handleChange(item.name, value)
                                }
                                options={item.options}
                              />
                            ) : (
                              <Select
                                showSearch
                                style={{
                                  width: "100%",
                                }}
                                placeholder={item.placeholder}
                                optionFilterProp="children"
                                disabled={item.isdisabled}
                                filterOption={(input, option) =>
                                  (option?.label ?? "").includes(input)
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase()
                                    )
                                }
                                value={formData[item.name] || undefined}
                                onChange={(value) =>
                                  handleChange(item.name, value)
                                }
                                options={item.options}
                              />
                            )}
                          </div>
                        </div>
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
            </div>
            <div className="multiformfooter">
              <Formfooter />
            </div>
          </div>
        );
      // ... add more cases for additional steps
      default:
        return null;
    }
  };
  const [jsonList, setJsonList] = useState([]);
  const addValue = (newValue) => {
    const updatedList = [...jsonList, newValue];
    setJsonList(updatedList);
    // Update localStorage with the updated list
    localStorage.setItem("jsonList", JSON.stringify(updatedList));
  };
  const initialData = {
    name: "Student Registration",
    link: "/student_registration",
    active: "no",
  };

  // useEffect to add initial data to the JSON list
  useEffect(() => {
    Tabhelper(initialData, setJsonList);
  }, []);

  //

  async function fetchReferenceData() {
    try {
      const response = await axios.get(apiurl + "reference/");
      setreferencedata(response.data);
    } catch (error) {
      alert("Network error");
    }
  }
  async function fetchData() {
    try {
      const response = await axios.get(apiurl + "studentreg/");
      settotalcount(response.data.length);
    } catch (error) {
      alert("Network error");
    }
  }
  const generateID = (event) => {
    if (
      formData.course == "" ||
      formData.batch == "" ||
      formData.firstname == "" ||
      formData.lastname == "" ||
      formData.date_of_birth == "" ||
      formData.gender == "" ||
      formData.contact1 == "" ||
      formData.contact2 == "" ||
      formData.religion == "" ||
      formData.total_fee == "" ||
      formData.scholorship == "" ||
      formData.branch == ""
    ) {
      alert("Please Enter All the Required Data..!");
    } else {
      event.preventDefault();
      const current = new Date();
      const date2 = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`;
      let [d, m, y] = date2.split(/\D/);
      const date = new Date(y, --m, d);
      const fullyear = date.getFullYear();
      const number = totalcount + 1;
      const paddedNumber = number.toString().padStart(4, "0");
      const sid = "AR" + formData.batch + paddedNumber;
      console.log();
      // setstudentid(sid);

      setFormData((prevState) => ({
        ...prevState,
        std_name: `${prevState.firstname.toUpperCase()} ${prevState.lastname.toUpperCase()}`,
        firstname: prevState.firstname.toUpperCase(),
        lastname: prevState.lastname.toUpperCase(),
        scholorship_amount: parseFloat(prevState.scholorship_percentage),
        remainig_fee:
          parseInt(prevState.total_fee) -
          parseFloat(prevState.scholorship_percentage),
        after_scholorship:
          parseInt(prevState.total_fee) -
          parseFloat(prevState.scholorship_percentage),
      }));
      setisverified(true);

      handleChange("student_id", sid);
    }
  };

  async function fetchData() {
    try {
      const response = await axios.get(apiurl + "studentreg/");
      const response2 = await axios.get(apiurl + `studentreg/${id}/`);
      setFormData(response2.data);
      console.log(response2.data);
      setUsername(response2.data.student_id);
      settotalcount(response.data.length);

      const current = new Date();
      const date2 = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`;
      let [d, m, y] = date2.split(/\D/);
      const date = new Date(y, --m, d);
      const datemonth = `${current.getMonth() + 1}`;
      const datedate = `${current.getDate()}`;

      const autobatch =
        datemonth <= 3
          ? `${current.getFullYear() % 100}`
          : `${(current.getFullYear() + 1) % 100}`;

      setFormData((prevState) => ({
        ...prevState,
        course: "",
        duration: "",
        total_fee: 0,
        after_scholorship: "",
        scholorship: "No",
        scholorship_percentage: "0",
        join_date: "",
        end_date: null,
        branch: "",
        status: "Active",
        reference: "",
        source: "",
        student_id: "",
        batch: autobatch,
        studend_image: null,
        fee_paid: 0,
        // sno: response.data.length + 289,
      }));
    } catch (error) {
      console.error(error);
      alert("Network error");
    }
  }
  const [isbtnvisible, setisbtnvisible] = useState(false);
  async function fetchuseaccountData() {
    try {
      const response = await axios.get(apiurl + "usercount/");
      console.warn(
        response.data[0].user_count > response.data[0].valid_user_count
      );
      if (response.data[0].user_count >= response.data[0].valid_user_count) {
        alert("Maximum users Created..!");
        navigate(`/dashboard/${user}`);
      } else {
        setisbtnvisible(true);
      }
    } catch (error) {
      alert("Network error");
    }
  }
  useEffect(() => {
    fetchuseaccountData();
    fetchData();
    // fetchData2();
    fetchReferenceData();
  }, []);
  return (
    <Maincomponent tabname={"Student Update"}>
      <div className="component-sub">
        <div className="registercomp1">{renderStep()}</div>
        {/* <button onClick={generateID}>Test</button> */}
      </div>
    </Maincomponent>
  );
}
