import React from "react";
import Logo from "../../Images/logoash2.png";
import { BarLoader } from "react-spinners";

const Loader = () => {
  return (
    <>
      <div className="loaderbackground">
        <img src={Logo} width={"15%"} className="imagelogoloader" />
        <br />
        <div style={{ display: "flex" }}>
          {" "}
          <BarLoader color="#18558A" height={5} width={250} />
          {/* <PulseLoader color="white" /> */}
        </div>
      </div>
    </>
  );
};

export default Loader;
