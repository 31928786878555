import React, { useEffect, useState } from "react";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { Input } from "antd";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
// import { Select } from "antd";
import { Space, Table, Tag } from "antd";
import axios from "axios";
import Apiurl from "../../Apiurl";
import Button from "react-bootstrap/Button";
import { Tabhelper } from "../../Helper/Tabhelper";

export default function Employeeupdate() {
  const [jsonList, setJsonList] = useState([]);

  const initialData = {
    name: "Employee Creation",
    link: "/employee_creation",
    active: "no",
  };
  //
  const apiurl = Apiurl();
  const { user } = useParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const [roledata, setroledata] = useState([]);
  const [name, setname] = useState("");
  const [role, setrole] = useState("");
  const [email, setemail] = useState("");
  const [contact, setcontact] = useState("");
  const [status, setstatus] = useState("");
  const [staffid, setstaffid] = useState("");
  const handleSubmit = async () => {
    const formdata = {
      Name: name,
      Role: role,
      Contact_number: contact,
      Email: email,
      Status: status,
      Staffid: staffid,
    };
    try {
      const response = await axios.patch(
        apiurl + `staffdetails/${id}/`,
        formdata
      );

      console.log(formdata);
      alert("Updated!");
      navigatefunction();
    } catch (error) {
      console.error(error);
      alert("Some Error occured\nTry again Later");
    }
  };
  async function fetchroledata() {
    try {
      const response = await axios.get(apiurl + "staffrole/");
      setroledata(response.data);
    } catch (error) {
      alert("Network error");
    }
  }
  async function fetchData() {
    try {
      const response = await axios.get(apiurl + `staffdetails/${id}/`);
      console.log(response.data);
      setname(response.data.Name);
      setrole(response.data.Role);
      setemail(response.data.Email);
      setstatus(response.data.Status);
      setstaffid(response.data.Staffid);
    } catch (error) {
      console.error(error);
    }
  }
  function navigatefunction(data) {
    let path = "/staff_profile/" + user;
    navigate(path);
  }

  useEffect(() => {
    fetchroledata();
    fetchData();
  }, []);

  //
  useEffect(() => {
    Tabhelper(initialData, setJsonList);
  }, []);

  const data = [
    { label: "Active", value: "Active" },
    { label: "Resigned", value: "Resigned" },
    { label: "Relieved", value: "Relieved" },
  ];

  return (
    <Maincomponent tabname={"Employee Creation"}>
      <div className="component-sub">
        <div className="component-sub1">
          <div className="multistephead">
            <b>EMPLOYEE CREATION</b>
          </div>
          <br />
          <div className="Billcompmain">
            {/* <div className="Billcompmain1"></div> */}
            <div className="Billcompmain2">
              {" "}
              <div className="multistepcontainer1">
                <div className="multistepsubcontainer1">
                  <div className="multisteptable">
                    {/*  */}
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Name</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          //  disabled
                          value={name}
                          onChange={(e) => {
                            setname(e.target.value);
                          }}
                          className="textipbill"
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1"> Role</div>
                      <div className="multisteptablecell2">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                          name="branch"
                          required
                          value={role}
                          onChange={(e) => {
                            setrole(e.target.value);
                          }}
                        >
                          <option value="">Select an option</option>

                          {roledata.map((option) => (
                            <option value={option.Role} key={option.id}>
                              {option.Role}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Email</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          onChange={(e) => {
                            setemail(e.target.value);
                          }}
                          value={email}
                          // disabled
                          className="textipbill"
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Status</div>
                      <div className="multisteptablecell2">
                        {/* <Input
                          type="text"
                          onChange={(e) => {
                            setstatus(e.target.value);
                          }}
                          value={status}
                          className="textipbill"
                        /> */}
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                          name="branch"
                          required
                          value={status}
                          onChange={(e) => {
                            setstatus(e.target.value);
                          }}
                        >
                          <option value="">Select an option</option>

                          {data.map((option, index) => (
                            <option value={option.value} key={option.index}>
                              {option.value}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Staff ID</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          onChange={(e) => {
                            setstaffid(e.target.value);
                          }}
                          disabled
                          value={staffid}
                          className="textipbill"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="multistepcontainerbtn">
                <button className="buttoncompnent1" onClick={handleSubmit}>
                  Update
                </button>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="Billcompmain">{/* <Tablecomp data={data} /> */}</div>
        </div>
      </div>
    </Maincomponent>
  );
}
