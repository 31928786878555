import React, { useEffect, useState } from "react";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { Input } from "antd";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Apiurl from "../../Apiurl";

import Button from "react-bootstrap/Button";
import { Tabhelper } from "../../Helper/Tabhelper";

export default function Batchentry() {
  const apiurl = Apiurl();

  const { user } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [data2, setData2] = useState([]);

  const [testtype, settesttype] = useState("");
  const [batch_name, setbatch_name] = useState("");
  const [status, setstatus] = useState("");

  const [Description, setdescription] = useState("");
  const options = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
    { label: "Completed", value: "Completed" },
  ];

  async function deleteData(id) {
    if (window.confirm("Are you sure you want to Delete this?")) {
      try {
        const response = await axios.delete(apiurl + "batchdata/" + id + "/");
        console.log(response.data);
        fetchData();
        alert("Deleted Sucessfully !");
        // Handle the response data here
      } catch (error) {
        console.error(error);
        // Handle error here
      }
    }
  }

  async function postData(event) {
    event.preventDefault();
    if (batch_name == "" || status == "" || Description == "") {
      alert("Enter All Fields...");
      return;
    }
    try {
      const formData = {
        batchname: batch_name,
        description: Description,
        status: status,
      };
      const response = await axios.post(apiurl + "batchdata/", formData);
      fetchData();
      alert("Added Sucessfully");
      setstatus("");
      setbatch_name("");
      setdescription("");
    } catch (error) {
      console.error(error);
      // Handle error here
    }
  }

  async function fetchData() {
    try {
      const response = await axios.get(apiurl + "batchdata/");
      console.log(response.data);
      setData2(response.data);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  //
  const Tablecomp = (props) => {
    const [data, setData] = useState(props.data.slice(0).reverse());

    const [postsPerPage, setPostsPerPage] = useState(3);
    const [currentPage, setCurrentPage] = useState(1);
    const showData = () => {
      const indexOfLastPage = currentPage * postsPerPage;
      const indexOfFirstPage = indexOfLastPage - postsPerPage;
      const currentPosts = data.slice(indexOfFirstPage, indexOfLastPage);

      try {
        return currentPosts.map((item, index) => (
          <tbody key={index}>
            <tr>
              <td>{postsPerPage * (currentPage - 1) + index + 1}</td>
              <td>{item.batchname}</td>
              <td>{item.status}</td>
              <td>{item.description}</td>
              <td>
                <Button
                  onClick={() => {
                    deleteData(item.id);
                  }}
                  size="sm"
                  variant="danger"
                >
                  <i className="fa fa-trash"></i>
                </Button>
                &nbsp;
                <Button
                  variant="warning"
                  size="sm"
                  onClick={() => {
                    navigate(`/batch_data_update/${user}/${item.id}`);
                  }}
                >
                  <i className="fa fa-edit"></i>
                </Button>
              </td>
            </tr>
          </tbody>
        ));
      } catch (e) {
        alert(e.message);
      }
    };

    const showPagination = () => {
      const pageNumbers = [];
      const totalPosts = data.length;

      for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
      }

      const renderPageNumbers = pageNumbers.slice(
        Math.max(0, currentPage - 2),
        Math.min(currentPage + 1, pageNumbers.length)
      );

      return (
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(1)}
                className="page-link"
                disabled={currentPage === 1}
              >
                {"<<"}
              </button>
            </li>
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                className="page-link"
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
            </li>
            {renderPageNumbers.map((number) => (
              <li
                key={number}
                className={
                  currentPage === number ? "page-item active" : "page-item"
                }
              >
                <button
                  onClick={() => setCurrentPage(number)}
                  className="page-link"
                >
                  {number}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                className="page-link"
                disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
              >
                {">"}
              </button>
            </li>
            <li className="page-item">
              <button
                onClick={() =>
                  setCurrentPage(Math.ceil(totalPosts / postsPerPage))
                }
                className="page-link"
                disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
              >
                {">>"}
              </button>
            </li>
          </ul>
        </nav>
      );
    };
    return (
      <div className="container">
        <table className="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              {/* Sno	Bill no	Date	Amount Paid	Mode	Bill Status	Actions */}

              <th>Sno</th>
              <th> Batch Name</th>
              <th> Status</th>
              <th> Description</th>
              <th> Actions</th>
            </tr>
          </thead>
          {showData()}
        </table>

        <div style={{ float: "right" }}>{showPagination()}</div>
      </div>
    );
  };
  const [jsonList, setJsonList] = useState([]);
  //
  const initialData = {
    name: "Batch",
    link: "/batch_data",
    active: "no",
  };

  // useEffect to add initial data to the JSON list
  useEffect(() => {
    Tabhelper(initialData, setJsonList);
  }, []);
  return (
    <Maincomponent tabname={"Batch"}>
      <div className="component-sub">
        <div className="component-sub1">
          <div className="multistephead">
            <b>COURSE BATCH</b>
          </div>
          <br />
          <div className="Billcompmain">
            {/* <div className="Billcompmain1"></div> */}
            <div className="Billcompmain2">
              <div className="multistepcontainer1">
                <div className="multistepsubcontainer1">
                  <div className="multisteptable">
                    {/*  */}
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Batch Name</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          value={batch_name}
                          placeholder="Batch name"
                          onChange={(e) => {
                            setbatch_name(e.target.value);
                          }}
                          className="textipbill"
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Description</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          placeholder="Description"
                          onChange={(e) => {
                            setdescription(e.target.value);
                          }}
                          value={Description}
                          className="textipbill"
                        />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Status</div>
                      <div className="multisteptablecell2">
                        <select
                          className="form-select mb-0"
                          id="floatingSelect"
                          size="sm"
                          style={{ height: "35px", "font-size": "12px" }}
                          required
                          value={status}
                          onChange={(e) => {
                            setstatus(e.target.value);
                          }}
                        >
                          <option value="">Select an option</option>
                          {options.map((option, index) => (
                            <option value={option.value} key={index + 1}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="multistepcontainerbtn">
                <button className="buttoncompnent1" onClick={postData}>
                  Submit
                </button>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="Billcompmain">
            <Tablecomp data={data2} />
          </div>
        </div>
      </div>
    </Maincomponent>
  );
}
