import React, { useState, useEffect } from "react";
import axios from "axios";
import Apiurl from "../Apiurl";

export default function Kitfeeamt() {
  const apiurl = Apiurl();
  const [data, setdata] = useState("");
  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiurl}kitfee/`);

      setdata(response.data[0].amount);
    } catch (error) {
      console.warn("Error fetching data:", error);
      alert(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return data;
}
