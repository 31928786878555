export const Tabhelper = (initialData, setJsonList) => {
  const storedList = localStorage.getItem("jsonList");
  if (storedList) {
    const parsedList = JSON.parse(storedList);
    const lastThree = parsedList.slice(-4);
    if (!lastThree.some((item) => item.name === initialData.name)) {
      const updatedList = [...lastThree, initialData];
      setJsonList(updatedList);
      localStorage.setItem("jsonList", JSON.stringify(updatedList));
    }
  }
};
