import React, { useState, useEffect } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Apiurl from "../../Apiurl";
import { Select } from "antd";
import { Input } from "antd";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import Button from "react-bootstrap/Button";
import { Tabhelper } from "../../Helper/Tabhelper";
import Subjectoptions from "../../Options/Subjectoptions";
import Courseoptions2 from "../../Options/Courseoptions2";

export default function Course_fee() {
  const [jsonList, setJsonList] = useState([]);
  //
  let dollarIndianLocale = Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 2,
  });
  const { user } = useParams();
  const navigate = useNavigate();
  const apiurl = Apiurl();
  const [formData, setFormData] = useState({});
  const [data2, setData2] = useState([]);
  const courseoptions = Courseoptions2();
  const subjectoptions = Subjectoptions();

  const [Subject_options, setsubject_options] = useState([]);
  const [Board_options, setboard_options] = useState([]);

  const [course, setcourse] = useState(null);
  const [batch, setbatch] = useState("");
  const [subject, setsubject] = useState("");
  const [board, setboard] = useState("");
  const [fee, setfee] = useState("");
  const [desc, setdesc] = useState("");

  async function deleteData(id) {
    if (window.confirm("Are you sure you want to Delete this?")) {
      try {
        const response = await axios.delete(
          apiurl + "feestructure/" + id + "/"
        );
        console.log(response.data);
        fetchData();
        alert("Deleted Sucessfully !");
        // Handle the response data here
      } catch (error) {
        console.error(error);
        // Handle error here
      }
    }
  }
  async function postData(event) {
    event.preventDefault();
    const formdata = {
      course: course,
      batch: batch,
      board: board,
      subject: subject,
      fees: fee,
      description: desc,
    };
    try {
      console.log(formdata);
      const response = await axios.post(apiurl + "feestructure/", formdata);
      console.log(response.responseText);
      fetchData();
      alert("Added Sucessfully");
      setcourse(null);
      setfee("");
      setsubject(null);
    } catch (error) {
      console.error(error);
      if (error.response.data.message) {
        alert(error.response.data.message);
      }
      //   console.error(error.response.data);
      // Handle error here
    }
  }

  async function fetchData() {
    try {
      const response = await axios.get(apiurl + "feestructure/");
      console.log(response.data);
      setData2(response.data);
    } catch (error) {
      console.error(error);
    }
  }
  async function fetchsubjectdata(val) {
    try {
      console.log("subject--" + val);
      if (val == "NEET" || val == "JEE") {
        setsubject_options([]);
      } else {
        const response = await axios.get(apiurl + `subject/?board=${val}`);
        const options = response.data.map((student, index) => ({
          value: student.subject,
          label: student.subject,
        }));
        setsubject_options(options);
        console.warn(options);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchboarddata(val) {
    try {
      if (val == "Y1N" || val == "Y2N") {
        const response = await axios.get(apiurl + `filterboard/?board=NEET`);
        const options = response.data.map((student, index) => ({
          value: student.board,
          label: student.board,
        }));
        setboard_options(options);
      } else if (val == "Y1J" || val == "Y2J") {
        const response = await axios.get(apiurl + `filterboard/?board=JEE`);
        const options = response.data.map((student, index) => ({
          value: student.board,
          label: student.board,
        }));
        setboard_options(options);
      } else if (val == "REP" || val == "YLP" || val == "CC") {
        const response = [{ board: "NEET" }, { board: "JEE" }];
        const options = response.map((student, index) => ({
          value: student.board,
          label: student.board,
        }));
        setboard_options(options);
      } else {
        const valueaf = removeAlphabets(val);
        console.log(valueaf);
        const response = await axios.get(
          apiurl + `filterboard/?board=${parseInt(valueaf)}`
        );
        const options = response.data.map((student, index) => ({
          value: student.board,
          label: student.board,
        }));
        setboard_options(options);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function removeAlphabets(str) {
    return str.replace(/[a-zA-Z]/g, "");
  }
  const handleChangecourse = (selectedOption) => {
    const val = selectedOption.split(",");
    setcourse(val[0]);

    // fetchboarddata(val[0]);
    // fetchsubjectdata(val[0]);
    setdesc(val[1]);
  };
  const handleChangesubject = (selectedOption) => {
    setsubject(selectedOption);
  };
  const handleChangeboard = (selectedOption) => {
    setboard(selectedOption);
    fetchsubjectdata(selectedOption);
  };

  useEffect(() => {
    const current = new Date();
    const date2 = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    let [d, m, y] = date2.split(/\D/);
    const date = new Date(y, --m, d);
    const datemonth = `${current.getMonth() + 1}`;
    const datedate = `${current.getDate()}`;

    const autobatch =
      datemonth <= 3
        ? `${current.getFullYear() % 100}`
        : `${(current.getFullYear() + 1) % 100}`;
    setbatch(autobatch);
    fetchData();
  }, []);

  //
  const initialData = {
    name: "Fee Structure",
    link: "/course_fee",
    active: "no",
  };

  // useEffect to add initial data to the JSON list
  useEffect(() => {
    Tabhelper(initialData, setJsonList);
  }, []);

  const Tablecomp = (props) => {
    const [data, setData] = useState(props.data.slice(0).reverse());

    const [postsPerPage, setPostsPerPage] = useState(4);
    const [currentPage, setCurrentPage] = useState(1);
    const showData = () => {
      const indexOfLastPage = currentPage * postsPerPage;
      const indexOfFirstPage = indexOfLastPage - postsPerPage;
      const currentPosts = data.slice(indexOfFirstPage, indexOfLastPage);
      try {
        return currentPosts.map((item, index) => (
          <tbody key={index}>
            <tr>
              <td>{postsPerPage * (currentPage - 1) + index + 1}</td>

              <td>{item.course}</td>
              <td>{item.batch}</td>
              {/* <td>{item.board}</td>
              <td>{item.subject}</td> */}
              <td>{item.description}</td>
              <td> ₹ {dollarIndianLocale.format(item.fees)}</td>
              <td>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={() => {
                    const path = "/course_fee_update/" + user + "/" + item.id;
                    navigate(path);
                  }}
                >
                  <i className="fa fa-edit"></i>
                </Button>{" "}
                <Button
                  size="sm"
                  onClick={() => {
                    deleteData(item.id);
                  }}
                  variant="outline-danger"
                >
                  <i className="fa fa-trash"></i>
                </Button>{" "}
              </td>
            </tr>
          </tbody>
        ));
      } catch (e) {
        alert(e.message);
      }
    };

    const showPagination = () => {
      const pageNumbers = [];
      const totalPosts = data.length;

      for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
      }

      return (
        <nav>
          <ul className="pagination">
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={
                  currentPage === number ? "page-item active" : "page-item"
                }
              >
                <button
                  onClick={() => setCurrentPage(number)}
                  className="page-link"
                >
                  {number}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      );
    };
    return (
      <div className="container">
        <table className="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              {/* Sno	Bill no	Date	Amount Paid	Mode	Bill Status	Actions */}
              <th>Sno</th>
              <th>Course </th>
              <th> Batch</th>
              {/* <th> Board</th> */}
              {/* <th> Subject</th> */}
              <th> Description</th>
              <th> Fee</th>
              <th> Actions</th>
            </tr>
          </thead>
          {showData()}
        </table>

        <div style={{ float: "right" }}>{showPagination()}</div>
      </div>
    );
  };
  return (
    <Maincomponent tabname={"Fee Structure"}>
      <div className="component-sub">
        <div className="component-sub1">
          <div className="multistephead">
            <b> FEE STRUCTURE</b>
          </div>
          <br />
          <div className="Billcompmain">
            <div className="Billcompmain2">
              <div className="multistepcontainer1">
                <div className="multistepsubcontainer1">
                  <div className="multisteptable">
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Course</div>
                      <div className="multisteptablecell2">
                        <Select
                          style={{
                            width: "100%",
                          }}
                          className="textipbill"
                          value={course}
                          onChange={handleChangecourse}
                          options={courseoptions}
                          placeholder="Select Course"
                        />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Description</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          value={desc}
                          disabled
                          className="textipbill"
                        />
                      </div>
                    </div>

                    {/* <div className="multisteptablerow">
                      <div className="multisteptablecell1">Board</div>
                      <div className="multisteptablecell2">
                        <Select
                          style={{
                            width: "100%",
                          }}
                          className="textipbill"
                          value={board}
                          onChange={handleChangeboard}
                          options={Board_options}
                          placeholder="Select Subject"
                        />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Subject</div>
                      <div className="multisteptablecell2">
                        <Select
                          style={{
                            width: "100%",
                          }}
                          className="textipbill"
                          value={subject}
                          onChange={handleChangesubject}
                          options={Subject_options}
                          placeholder="Select Subject"
                        />
                      </div>
                    </div> */}
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Batch</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          value={batch}
                          onChange={(e) => {
                            setbatch(e.target.value);
                          }}
                          className="textipbill"
                        />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Fee</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          value={fee}
                          onChange={(e) => {
                            setfee(e.target.value);
                          }}
                          className="textipbill"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="multistepcontainerbtn">
                <button className="buttoncompnent1" onClick={postData}>
                  Submit
                </button>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="Billcompmain">
            <Tablecomp data={data2} />
          </div>
        </div>
      </div>
    </Maincomponent>
  );
}
