import React, { useEffect, useState } from "react";
import Sidebutton from "../Sidebutton/Sidebutton";
import Sidebuttondropdown from "../Sidebuttondropdown/Sidebuttondropdown";
import { Dropdown } from "antd";
import { FaRegUserCircle } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import Logo from "../../Images/logotop.png";
import { PiStudentFill } from "react-icons/pi";
import { IoCashOutline } from "react-icons/io5";
import { FaBriefcase } from "react-icons/fa6";
import { RiCustomerService2Fill } from "react-icons/ri";
import { IoWallet } from "react-icons/io5";
import { GiWhiteBook } from "react-icons/gi";
import { HiAcademicCap } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import Apiurl from "../../Apiurl";
import { useAuth } from "../../Auth";

import Cookies from "js-cookie";

export default function Maincomponent({ children, tabname }) {
  const navigate = useNavigate();
  const auth = useAuth();
  let { user } = useParams();

  const items = [
    {
      key: "1",
      label: (
        <a target="_blank" rel="noopener noreferrer">
          Profile
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          onClick={() => {
            window.localStorage.setItem("jsonList", JSON.stringify([]));
            window.localStorage.clear();

            navigate("/ashtrixrobotics");
          }}
        >
          Logout
        </a>
      ),
    },
  ];
  const getLanguageCookie = () => {
    return Cookies.get("name");
  };
  const Name = getLanguageCookie();

  // sidebardatas
  const Dropdowncomponenta = (props) => {
    return (
      <a
        onClick={() => {
          auth.login(user);
          navigate(`${props.urls}/${user}`);
        }}
      >
        {props.name}
      </a>
    );
  };

  const sidebardata = [
    {
      name: "Student",
      icon: <PiStudentFill />,
      item: "studentitem",
      sidedataitem: [
        {
          key: "1",
          label: (
            <Dropdowncomponenta
              name={"Registration"}
              urls={"/student_registration"}
            />
          ),
        },
        {
          key: "2",
          label: (
            <Dropdowncomponenta name={"Profile"} urls={"/student_profile"} />
          ),
        },
        {
          key: "3",
          label: (
            <Dropdowncomponenta
              name={"Attendance"}
              urls={"/student_attendance"}
            />
          ),
        },
        {
          key: "4",
          label: (
            <Dropdowncomponenta name={"Reports"} urls={"/student_reports"} />
          ),
        },
      ],
    },
    {
      name: "Finance",
      icon: <IoCashOutline />,
      item: "financeitem",
      sidedataitem: [
        {
          key: "1",
          label: (
            <Dropdowncomponenta
              name={"Registration"}
              urls={"/student_registration"}
            />
          ),
        },
        {
          key: "2",
          label: (
            <Dropdowncomponenta name={"Profile"} urls={"/student_profile"} />
          ),
        },
        {
          key: "3",
          label: (
            <Dropdowncomponenta
              name={"Attendance"}
              urls={"/student_attendance"}
            />
          ),
        },
        {
          key: "4",
          label: (
            <Dropdowncomponenta name={"Reports"} urls={"/student_reports"} />
          ),
        },
      ],
    },
    {
      name: "Expense",
      icon: <IoWallet />,
      item: "expenseitem",
      sidedataitem: [
        {
          key: "1",
          label: (
            <Dropdowncomponenta
              name={"Registration"}
              urls={"/student_registration"}
            />
          ),
        },
      ],
    },
    {
      name: "Academic",
      icon: <GiWhiteBook />,
      item: "",
      sidedataitem: [
        {
          key: "1",
          label: (
            <Dropdowncomponenta
              name={"Registration"}
              urls={"/student_registration"}
            />
          ),
        },
      ],
    },
    {
      name: "Institute",
      icon: <HiAcademicCap />,
      item: "",
      sidedataitem: [
        {
          key: "1",
          label: (
            <Dropdowncomponenta
              name={"Registration"}
              urls={"/student_registration"}
            />
          ),
        },
      ],
    },
    {
      name: "Employee",
      icon: <FaBriefcase />,
      item: "",
      sidedataitem: [
        {
          key: "1",
          label: (
            <Dropdowncomponenta
              name={"Registration"}
              urls={"/student_registration"}
            />
          ),
        },
      ],
    },
    {
      name: "CRM",
      icon: <RiCustomerService2Fill />,
      item: "",
      sidedataitem: [
        {
          key: "1",
          label: (
            <Dropdowncomponenta
              name={"Registration"}
              urls={"/student_registration"}
            />
          ),
        },
      ],
    },
  ];
  const apiurl = Apiurl();
  const [jsonList, setJsonList] = useState([]);
  const removeValue = (indexToRemove) => {
    const updatedList = [...jsonList];
    updatedList.splice(indexToRemove, 1);
    setJsonList(updatedList);
    window.localStorage.setItem("jsonList", JSON.stringify(updatedList));
  };
  const [dsidedata, setdsidedata] = useState([]);
  const [onlysidedata, setonlysidedata] = useState([]);
  const [autobatch, setautobatch] = useState("");
  const fetchData = async () => {
    try {
      const decodedUserType = await decodeURIComponent(user);
      console.warn(decodedUserType);

      const response = await axios.get(
        apiurl + `Role/?role_name=${decodedUserType}`
      );
      //   setData(response.data);
      setdsidedata(response.data);

      var data = JSON.stringify(
        response.data[0].role_data.sidebardata,
        null,
        2
      );
      const obj = JSON.parse(data);
      setonlysidedata(obj);

      const current = new Date();
      const date2 = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`;
      let [d, m, y] = date2.split(/\D/);
      const date = new Date(y, --m, d);
      const datemonth = `${current.getMonth() + 1}`;
      const datedate = `${current.getDate()}`;

      const autobatch =
        datemonth <= 3
          ? current.getFullYear() - 1 + "-" + current.getFullYear()
          : current.getFullYear() + "-" + (current.getFullYear() + 1);
      setautobatch(autobatch);
    } catch (error) {
      console.warn("Error fetching data:", error);
      alert(error);
    }
  };

  useEffect(() => {
    const storedList = window.localStorage.getItem("jsonList");
    if (storedList) {
      setJsonList(JSON.parse(storedList));
    }
    fetchData();
  }, []);

  return (
    <div className="maincomponent">
      <div className="navcomponent">
        <img className="navicon" src={Logo} height={35} width={37} />
        <div className="navcompsub">
          <div className="icon-name"> ASHTRIX ROBOTICS</div>
          {/* <div className="icon-name">ACADEMIC YEAR {autobatch}</div>  */}
          <div className="icon-name">
            {Name} &nbsp;
            <Dropdown
              menu={{
                items,
              }}
              arrow
            >
              <FaRegUserCircle className="usericon" />
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="main-subcomponent">
        <div className="sub-component1">
          <Sidebutton name="Dashboard" user={user} icon={<MdDashboard />} />

          {onlysidedata.map((item, index) => (
            <>
              {item.isvisible ? (
                <Sidebuttondropdown
                  dataitem={item.sidedataitem}
                  name={item.name}
                  icon={item.icon}
                  user={user}
                />
              ) : null}
            </>
          ))}
        </div>
        <div className="sub-component2">
          <div className="tabcomponent">
            <button
              className={tabname == "Welcome" ? "btntab2active" : "btntab2"}
              onClick={() => {
                auth.login(user);
                navigate(`/dashboard/${user}`);
              }}
            >
              Welcome
            </button>
            {/* .slice().reverse().slice(0,4) */}

            {jsonList.map((item, index) => (
              <div
                className={
                  tabname === item.name ? "btntabmainactive" : "btntabmain"
                }
              >
                <button
                  className={
                    tabname === item.name ? "btntab2active" : "btntab2"
                  }
                  onClick={() => {
                    auth.login(user);
                    navigate(`${item.link}/${user}`);
                  }}
                >
                  {item.name} &nbsp;
                </button>{" "}
                <span
                  className={
                    tabname === item.name ? "btntab2activespan" : "btntab2"
                  }
                  onClick={() => {
                    removeValue(index);
                  }}
                  style={{ fontSize: "12px" }}
                >
                  X
                </span>
              </div>
            ))}

            {/* <Tabs
              onChange={onChange}
              type="card"
              items={new Array(1).fill(null).map((_, i) => {
                const id = String(i + 1);
                return {
                  label: <>{`Welcome`} </>,
                  key: id,
                  children: <Dashboard />,
                };
              })}
            /> */}
          </div>
          <div className="areacomponent">{children}</div>
          <div className="footercomponent">
            <div> Legal &#x2666; Privacy &#x2666; Security</div>
            <div>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(`https://cbctech.co.in/`);
                }}
              >
                Developed by CBC INFOTECH
              </span>{" "}
              &copy; 2024 All Rights Reserved.
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
