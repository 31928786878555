import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AuthProvider } from "./Auth";
import RequireAuth from "./RequireAuth";
import { useAuth } from "./Auth";

import "./App.sass";
import Loader from "./Components/Loader/Loader.js";
import Employeeattendance from "./Pages/Employee/Employeeattendance.js";
import Basedata_upload from "./Pages/Crm/Basedata_upload.js";
import Enquiry from "./Pages/Crm/Enquiry.js";
import Prospect from "./Pages/Crm/Prospect.js";
import Followup from "./Pages/Crm/Followup.js";
import Basedata_creation from "./Pages/Crm/Basedata_creation.js";
import Testcreationupdate from "./Pages/Academic/Tesrcreationupdate.js";
import Timetable from "./Pages/Institute/Timetable.js";
import Student_existing from "./Pages/Student/Student_existing.js";
import Course_fee from "./Pages/Institute/Course_fee.js";
import Course_fee_update from "./Pages/Institute/Course_fee_update.js";
import Profilebulkupdate from "./Pages/Student/Profilebulkupdate.js";
import Bankdetails from "./Pages/Finance/Bankdetails.js";
import Bankdetailsupdate from "./Pages/Finance/Bankdetailsupdate.js";
import Courseupdate from "./Pages/Institute/Courseupdate.js";
import Payslippdf from "./Components/Pdfprinter/Payslippdf.js";
import Employeeupdate from "./Pages/Employee/Eployeeupdate.js";
import Batchentry from "./Pages/Academic/Batchentry.js";
import Batchupdate from "./Pages/Academic/Batchupdate.js";
import Kitfeeentry from "./Pages/Academic/Kitfeeentry.js";
import Branchupdate from "./Pages/Institute/Branchupdate.js";

const Dashboard = React.lazy(() => import("./Pages/Dashboard/Dashboard.js"));
const Student_registration = React.lazy(() =>
  import("./Pages/Student/Student_registration.js")
);
const Student_attendance = React.lazy(() =>
  import("./Pages/Student/Student_attendance.js")
);
const Student_profile = React.lazy(() =>
  import("./Pages/Student/Student_profile.js")
);
const Student_reports = React.lazy(() =>
  import("./Pages/Student/Student_reports.js")
);
const Loginpage = React.lazy(() => import("./Pages/Loginpage/Loginpage.js"));
const Userdashboard = React.lazy(() =>
  import("./Pages/Dashboard/Userdashboard.js")
);
const Role = React.lazy(() => import("./Pages/Role/Role.js"));
const Dummycomp = React.lazy(() => import("./Components/Dummycomp.js"));
const Billing = React.lazy(() => import("./Pages/Finance/Billing.js"));
const Directreceipts = React.lazy(() =>
  import("./Pages/Finance/Directreceipt.js")
);
const Fundtransfer = React.lazy(() =>
  import("./Pages/Finance/Fundtransfer.js")
);
const Branch = React.lazy(() => import("./Pages/Institute/Branch.js"));
const Course = React.lazy(() => import("./Pages/Institute/Course.js"));
const Reference = React.lazy(() => import("./Pages/Institute/Reference.js"));
const Pdffeebill = React.lazy(() =>
  import("./Components/Pdfprinter/Pdfmakerup.js")
);
const Pdfincomeebill = React.lazy(() =>
  import("./Components/Pdfprinter/Pdfincomemaker.js")
);
const Studentviewpdfid = React.lazy(() =>
  import("./Components/Pdfprinter/Studentviewpdfid.js")
);
const Expense = React.lazy(() => import("./Pages/Expense/Expense.js"));
const Pettycash = React.lazy(() => import("./Pages/Expense/Pettycash.js"));
const Test_creation = React.lazy(() =>
  import("./Pages/Academic/Testcreation.js")
);
const Markentry = React.lazy(() => import("./Pages/Academic/Markentry.js"));
const Markupdate = React.lazy(() => import("./Pages/Academic/Markupdate.js"));
const Exam = React.lazy(() => import("./Pages/Institute/Exam.js"));
const Examupdate = React.lazy(() => import("./Pages/Institute/Examupdate.js"));
const Employeecreation = React.lazy(() =>
  import("./Pages/Employee/Employeecreation.js")
);
const Student_master = React.lazy(() =>
  import("./Pages/Student/Reports/Student_master.js")
);
const Student_update = React.lazy(() =>
  import("./Pages/Student/Student_update.js")
);
const Outstanding_reports = React.lazy(() =>
  import("./Pages/Finance/Reports/Outstanding_reports.js")
);
const Studentviewpdfoutstanding = React.lazy(() =>
  import("./Components/Pdfprinter/Pdfoutstandingreport.js")
);
const Billing_reports = React.lazy(() =>
  import("./Pages/Finance/Reports/Billing_reports.js")
);
const Testwise = React.lazy(() =>
  import("./Pages/Academic/Reports/Testwise.js")
);
const Studentwise = React.lazy(() =>
  import("./Pages/Academic/Reports/Studentwise.js")
);
const Coursewise = React.lazy(() =>
  import("./Pages/Academic/Reports/Coursewise.js")
);
const Dr_reports = React.lazy(() =>
  import("./Pages/Finance/Reports/Dr_reports.js")
);
const Ft_reports = React.lazy(() =>
  import("./Pages/Finance/Reports/Ft_reports.js")
);
const Expense_reports = React.lazy(() =>
  import("./Pages/Expense/Reports/Expense_reports.js")
);
const Pettycash_reports = React.lazy(() =>
  import("./Pages/Expense/Reports/Pettycash_reports.js")
);
const Student_attendance_reports = React.lazy(() =>
  import("./Pages/Student/Reports/Student_attendance.js")
);
const Employeeroles = React.lazy(() =>
  import("./Pages/Employee/Employeeroles.js")
);
const Employee_profile = React.lazy(() =>
  import("./Pages/Employee/Employeeprofile.js")
);
const Staffview = React.lazy(() => import("./Pages/Employee/Staffview.js"));
const Coursewiseupdated = React.lazy(() =>
  import("./Pages/Academic/Reports/Coursewiseupdated.js")
);
const Testcodes = React.lazy(() => import("./Pages/Testcodes/Testcodes.js"));
const DisableKeys = () => {
  useEffect(() => {
    const disableContextMenu = (e) => e.preventDefault();

    const ctrlShiftKey = (e, keyCode) =>
      e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);

    const disableKeys = (e) => {
      if (
        e.keyCode === 123 || // F12
        ctrlShiftKey(e, "I") || // Ctrl + Shift + I
        ctrlShiftKey(e, "J") || // Ctrl + Shift + J
        ctrlShiftKey(e, "C") || // Ctrl + Shift + C
        (e.ctrlKey && e.keyCode === "U".charCodeAt(0)) // Ctrl + U
      ) {
        e.preventDefault();
        return false;
      }
    };

    document.addEventListener("contextmenu", disableContextMenu);
    document.addEventListener("keydown", disableKeys);

    return () => {
      document.removeEventListener("contextmenu", disableContextMenu);
      document.removeEventListener("keydown", disableKeys);
    };
  }, []);

  return null;
};
function App() {
  return (
    <AuthProvider>
      <DisableKeys />
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Routes>
            <Route path="/ashtrixrobotics" element={<Loginpage />} exact />
            <Route
              path="/dashboard/:user"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
              exact
            />
            {/* Student */}
            <Route
              path="/bulk_student_profile/:user"
              element={
                // <RequireAuth>
                <Profilebulkupdate />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/student_registration/:user"
              element={
                // <RequireAuth>
                <Student_registration />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/student_update/:id/:user"
              element={
                <RequireAuth>
                  <Student_update />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/student_existing/:id/:user"
              element={
                // <RequireAuth>
                <Student_existing />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/student_attendance/:user"
              element={
                <RequireAuth>
                  <Student_attendance />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/student_profile/:user"
              element={
                <RequireAuth>
                  <Student_profile />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/student_reports/:user"
              element={
                <RequireAuth>
                  <Student_reports />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/student_master/:user"
              element={
                <RequireAuth>
                  <Student_master />
                </RequireAuth>
              }
              exact
            />

            <Route
              path="/user_dashboard/:user"
              element={
                // <RequireAuth>
                <Userdashboard />
                // </RequireAuth>
              }
              exact
            />

            <Route
              path="/role/:user"
              element={
                <RequireAuth>
                  <Role />{" "}
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/attendance_reports/:user"
              element={
                // <RequireAuth>
                <Student_attendance_reports />
                // </RequireAuth>
              }
              exact
            />

            {/* Finance */}
            <Route
              path="/billing/:user"
              element={
                <RequireAuth>
                  <Billing />{" "}
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/bank_details/:user"
              element={
                // <RequireAuth>
                <Bankdetails />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/bank_details_update/:user/:id"
              element={
                // <RequireAuth>
                <Bankdetailsupdate />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/direct_receipt/:user"
              element={
                <RequireAuth>
                  <Directreceipts />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/fund_transfer/:user"
              element={
                <RequireAuth>
                  {" "}
                  <Fundtransfer />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/billing_reports/:user"
              element={
                // <RequireAuth>

                <Billing_reports />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/dr_reports/:user"
              element={
                // <RequireAuth>

                <Dr_reports />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/ft_reports/:user"
              element={
                // <RequireAuth>

                <Ft_reports />
                // </RequireAuth>
              }
              exact
            />
            {/* institute */}
            <Route
              path="/add_branch/:user"
              element={
                <RequireAuth>
                  {" "}
                  <Branch />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/update_branch/:user/:id"
              element={
                <RequireAuth>
                  {" "}
                  <Branchupdate />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/add_course/:user"
              element={
                <RequireAuth>
                  <Course />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/course_update/:id/:user"
              element={
                // <RequireAuth>
                <Courseupdate />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/course_fee/:user"
              element={
                <RequireAuth>
                  <Course_fee />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/add_reference/:user"
              element={
                <RequireAuth>
                  <Reference />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/exam/:type/:user"
              element={
                <RequireAuth>
                  <Exam />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/examupdate/:data/:id/:user"
              element={
                <RequireAuth>
                  <Examupdate />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/timetable/:user"
              element={
                // <RequireAuth>
                <Timetable />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/course_fee_update/:user/:id"
              element={
                // <RequireAuth>
                <Course_fee_update />
                // </RequireAuth>
              }
              exact
            />

            {/* Expense */}
            <Route
              path="/expense/:user"
              element={
                <RequireAuth>
                  <Expense />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/petty_cash/:user"
              element={
                <RequireAuth>
                  <Pettycash />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/expense_reports/:user"
              element={
                // <RequireAuth>
                <Expense_reports />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/pc_reports/:user"
              element={
                // <RequireAuth>
                <Pettycash_reports />
                // </RequireAuth>
              }
              exact
            />

            {/* pdf  Printings */}

            <Route
              path="/student_admission_view/:id"
              element={<Studentviewpdfid />}
              exact
            />

            <Route path="/pdffeebill/:data" element={<Pdffeebill />} exact />
            <Route
              path="/pdfincomebill/:data"
              element={<Pdfincomeebill />}
              exact
            />
            {/* Academic */}
            <Route
              path="/test_creation/:user"
              element={
                // <RequireAuth>

                <Test_creation />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/kit_entry/:user"
              element={
                // <RequireAuth>
                <Kitfeeentry />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/batch_data/:user"
              element={
                // <RequireAuth>

                <Batchentry />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/batch_data_update/:user/:id"
              element={
                // <RequireAuth>
                <Batchupdate />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/test_creation_update/:user/:id"
              element={
                <RequireAuth>
                  {" "}
                  <Testcreationupdate />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/mark_entry/:user"
              element={
                <RequireAuth>
                  <Markentry />{" "}
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/mark_update/:user"
              element={
                <RequireAuth>
                  <Markupdate />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="test_reports/:user"
              element={
                // <RequireAuth>
                <Testwise />
                // </RequireAuth>
              }
            />
            <Route
              path="studentwise_reports/:user"
              element={
                // <RequireAuth>
                <Studentwise />
                // </RequireAuth>
              }
            />
            <Route
              path="coursewise_reports/:user"
              element={
                // <RequireAuth>
                <Coursewiseupdated />
                // </RequireAuth>
              }
            />
            {/* Employee */}
            <Route
              path="/employee_creation/:user"
              element={
                <RequireAuth>
                  <Employeecreation />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/employee_update/:user/:id"
              element={
                <RequireAuth>
                  <Employeeupdate />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/outstanding_reports/:user"
              element={<Outstanding_reports />}
              exact
            />
            <Route
              path="/employee_attendance/:user"
              element={<Employeeattendance />}
              exact
            />
            <Route
              path="/staff_profile/:user"
              element={<Employee_profile />}
              exact
            />
            <Route path="/staff_view/:Id/:user" element={<Staffview />} exact />
            <Route
              path="outstanding_reports/student_outstanding/:id"
              element={
                // <RequireAuth>
                <Studentviewpdfoutstanding />
                // </RequireAuth>
              }
            />
            <Route
              path="/add_role/:user"
              element={
                // <RequireAuth>
                <Employeeroles />
                // </RequireAuth>
              }
            />
            <Route
              path="/testcodes"
              element={
                // <RequireAuth>
                <Testcodes />
                // </RequireAuth>
              }
            />
            {/* CRM */}
            <Route
              path="/basedata_upload/:user"
              element={
                // <RequireAuth>
                <Basedata_upload />
                // </RequireAuth>
              }
            />
            <Route
              path="/basedata_creation/:user"
              element={
                // <RequireAuth>
                <Basedata_creation />
                // </RequireAuth>
              }
            />
            <Route
              path="/enquiry/:user"
              element={
                // <RequireAuth>
                <Enquiry />
                // </RequireAuth>
              }
            />
            <Route
              path="/prospect/:user"
              element={
                // <RequireAuth>
                <Prospect />
                // </RequireAuth>
              }
            />
            <Route
              path="/followup/:user"
              element={
                // <RequireAuth>
                <Followup />
                // </RequireAuth>
              }
            />
            {/* Payslip */}
            <Route path="/pdfpayslip/" element={<Payslippdf />} exact />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </AuthProvider>
  );
}

export default App;
