import React, { useEffect, useState } from "react";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { Input } from "antd";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Apiurl from "../../Apiurl";

import Button from "react-bootstrap/Button";
import { Tabhelper } from "../../Helper/Tabhelper";

export default function Kitfeeentry() {
  const apiurl = Apiurl();
  const [data2, setData2] = useState([]);
  const [testtype, settesttype] = useState("");
  const [batch_name, setbatch_name] = useState("Kit Fee");
  const [isupdate, setisupdate] = useState(false);
  const [Description, setdescription] = useState("");
  const [amount, setamount] = useState("");


  async function postData(event) {
    event.preventDefault();
    if (amount == "" || Description == "") {
      alert("Enter All Fields...");
      return;
    }
    try {
      const formData = {
        name: "Kit Fee",
        amount: amount,
        description: Description,
      };
      const response = await axios.patch(apiurl + "kitfee/1/", formData);
      fetchData();
      alert("Updated Sucessfully");
      setisupdate(false);
    } catch (error) {
      console.error(error);
      // Handle error here
    }
  }

  async function fetchData() {
    try {
      const response = await axios.get(apiurl + "kitfee/");
      console.log(response.data);
      setData2(response.data);
      setamount(response.data[0].amount);
      setdescription(response.data[0].description);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  //
  const Tablecomp = (props) => {
    const [data, setData] = useState(props.data.slice(0).reverse());

    const [postsPerPage, setPostsPerPage] = useState(3);
    const [currentPage, setCurrentPage] = useState(1);
    const showData = () => {
      const indexOfLastPage = currentPage * postsPerPage;
      const indexOfFirstPage = indexOfLastPage - postsPerPage;
      const currentPosts = data.slice(indexOfFirstPage, indexOfLastPage);

      try {
        return currentPosts.map((item, index) => (
          <tbody key={index}>
            <tr>
              <td>{postsPerPage * (currentPage - 1) + index + 1}</td>
              <td>{item.name}</td>
              <td>{item.amount}</td>
              <td>{item.description}</td>
              <td>
                {/* <Button
                  onClick={() => {
                    deleteData(item.id);
                  }}
                  size="sm"
                  variant="danger"
                >
                  <i className="fa fa-trash"></i>
                </Button>
                &nbsp; */}
                <Button
                  variant="warning"
                  size="sm"
                  onClick={() => {
                    setisupdate(true);
                  }}
                >
                  <i className="fa fa-edit"></i>
                </Button>
              </td>
            </tr>
          </tbody>
        ));
      } catch (e) {
        alert(e.message);
      }
    };

    const showPagination = () => {
      const pageNumbers = [];
      const totalPosts = data.length;

      for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
      }

      const renderPageNumbers = pageNumbers.slice(
        Math.max(0, currentPage - 2),
        Math.min(currentPage + 1, pageNumbers.length)
      );

      return (
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(1)}
                className="page-link"
                disabled={currentPage === 1}
              >
                {"<<"}
              </button>
            </li>
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                className="page-link"
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
            </li>
            {renderPageNumbers.map((number) => (
              <li
                key={number}
                className={
                  currentPage === number ? "page-item active" : "page-item"
                }
              >
                <button
                  onClick={() => setCurrentPage(number)}
                  className="page-link"
                >
                  {number}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                className="page-link"
                disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
              >
                {">"}
              </button>
            </li>
            <li className="page-item">
              <button
                onClick={() =>
                  setCurrentPage(Math.ceil(totalPosts / postsPerPage))
                }
                className="page-link"
                disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
              >
                {">>"}
              </button>
            </li>
          </ul>
        </nav>
      );
    };
    return (
      <div className="container">
        <table className="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              {/* Sno	Bill no	Date	Amount Paid	Mode	Bill Status	Actions */}

              <th>Sno</th>
              <th> Name</th>
              <th> Amount</th>
              <th> Description</th>
              <th> Actions</th>
            </tr>
          </thead>
          {showData()}
        </table>

        <div style={{ float: "right" }}>{showPagination()}</div>
      </div>
    );
  };
  const [jsonList, setJsonList] = useState([]);
  //
  const initialData = {
    name: "Kit Fee",
    link: "/kit_entry",
    active: "no",
  };

  // useEffect to add initial data to the JSON list
  useEffect(() => {
    Tabhelper(initialData, setJsonList);
  }, []);
  return (
    <Maincomponent tabname={"Kit Fee"}>
      <div className="component-sub">
        <div className="component-sub1">
          <div className="multistephead">
            <b>KIT FEE</b>
          </div>
          <br />
          <div className="Billcompmain">
            {/* <div className="Billcompmain1"></div> */}
            {isupdate ? (
              <div className="Billcompmain2">
                <div className="multistepcontainer1">
                  <div className="multistepsubcontainer1">
                    <div className="multisteptable">
                      {/*  */}
                      <div className="multisteptablerow">
                        <div className="multisteptablecell1">Name</div>
                        <div className="multisteptablecell2">
                          <Input
                            type="text"
                            value={batch_name}
                            placeholder="Kit Fee"
                            onChange={(e) => {
                              setbatch_name(e.target.value);
                            }}
                            className="textipbill"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="multisteptablerow">
                        <div className="multisteptablecell1">Amount</div>
                        <div className="multisteptablecell2">
                          <Input
                            type="text"
                            placeholder="Amount"
                            onChange={(e) => {
                              setamount(e.target.value);
                            }}
                            value={amount}
                            className="textipbill"
                          />
                        </div>
                      </div>
                      <div className="multisteptablerow">
                        <div className="multisteptablecell1">Description</div>
                        <div className="multisteptablecell2">
                          <Input
                            type="text"
                            placeholder="Description"
                            onChange={(e) => {
                              setdescription(e.target.value);
                            }}
                            value={Description}
                            className="textipbill"
                          />
                        </div>
                      </div>
                      {/* <div className="multisteptablerow">
                      <div className="multisteptablecell1">Status</div>
                      <div className="multisteptablecell2">
                        <select
                          className="form-select mb-0"
                          id="floatingSelect"
                          size="sm"
                          style={{ height: "35px", "font-size": "12px" }}
                          required
                          value={status}
                          onChange={(e) => {
                            setstatus(e.target.value);
                          }}
                        >
                          <option value="">Select an option</option>
                          {options.map((option, index) => (
                            <option value={option.value} key={index + 1}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
                <div className="multistepcontainerbtn">
                  <button className="buttoncompnent1" onClick={postData}>
                    Submit
                  </button>
                </div>
              </div>
            ) : null}
          </div>
          <br />
          <br />
          {!isupdate ? (
            <div className="Billcompmain">
              <Tablecomp data={data2} />
            </div>
          ) : null}
        </div>
      </div>
    </Maincomponent>
  );
}
