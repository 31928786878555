import React, { useState, useEffect } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Apiurl from "../../Apiurl";
import { Select } from "antd";
import { Input } from "antd";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import Button from "react-bootstrap/Button";
import { Tabhelper } from "../../Helper/Tabhelper";

export default function Branchupdate() {
  const [jsonList, setJsonList] = useState([]);
  const { user } = useParams();
  const { id } = useParams();
  const apiurl = Apiurl();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [data2, setData2] = useState([]);
  const [data, setdata] = useState({
    fields: [
      {
        name: "branch_name",
        label: "Name",
        type: "inputtxt",
        isdisabled: true,
      },
      {
        name: "description",
        label: "Description ",
        type: "inputtxt",
        isdisabled: false,
      },
      {
        name: "b_address",
        label: "Address ",
        type: "inputtxt",
        isdisabled: false,
      },
      {
        name: "b_phone",
        label: "Phone ",
        type: "inputtxt",
        isdisabled: false,
      },
      {
        name: "b_web",
        label: "Website ",
        type: "inputtxt",
        isdisabled: false,
      },
      {
        name: "b_email",
        label: "Email ",
        type: "inputtxt",
        isdisabled: false,
      },
    ],
  });
  async function fetchData() {
    try {
      const response = await axios.get(apiurl + `branch/${id}/`);
      console.log(response.data);
      setFormData(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  async function postData(event) {
    event.preventDefault();
    try {
      const response = await axios.patch(apiurl + `branch/${id}/`, formData);
      fetchData();
      alert("Branch Updated Sucessfully !");
      navigate(`/add_branch/${user}`);
      setFormData({});
    } catch (error) {
      console.error(error);
      // Handle error here
    }
  }
  // };
  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);
  //
  const initialData = {
    name: "Branch",
    link: "/add_branch",
    active: "no",
  };

  // useEffect to add initial data to the JSON list
  useEffect(() => {
    Tabhelper(initialData, setJsonList);
  }, []);

  return (
    <Maincomponent tabname={"Branch"}>
      <div className="component-sub">
        <div className="component-sub1">
          <div className="multistephead">
            <b>BRANCH</b>
          </div>
          <br />
          <div className="Billcompmain">
            {/* <div className="Billcompmain1"></div> */}
            <div className="Billcompmain2">
              {" "}
              <div className="multistepcontainer1">
                <div className="multistepsubcontainer1">
                  <div className="multisteptable">
                    {/*  */}
                    {data.fields.map((item, index) => (
                      <div className="multisteptablerow" key={index}>
                        <div className="multisteptablecell1">{item.label}</div>
                        <div className="multisteptablecell2">
                          {item.type === "inputtxt" ? (
                            <Input
                              type="text"
                              name={item.name}
                              value={formData[item.name]}
                              disabled={item.isdisabled}
                              onChange={(e) =>
                                handleChange(item.name, e.target.value)
                              }
                            />
                          ) : (
                            <Select
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              placeholder={`Select ${item.label}`}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "").includes(input)
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              value={formData[item.name]}
                              onChange={(value) =>
                                handleChange(item.name, value)
                              }
                              options={item.options}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="multistepcontainerbtn">
                <button className="buttoncompnent1" onClick={postData}>
                  Submit
                </button>
              </div>
            </div>
          </div>
          <br />
          <br />
          {/* <div className="Billcompmain">
            <Tablecomp data={data2} />
          </div> */}
        </div>
      </div>
    </Maincomponent>
  );
}
